
export interface Trip {
  trip: string;
  truckType: string;
  ton: number;
  rate: number;
}

export const tripData: Trip[] = [
  { trip: "Abu Dhabi to Barka", truckType: "12M Flatbed", ton: 22, rate: 2400 },
  { trip: "Abu Dhabi to Dammam", truckType: "12M Flatbed", ton: 18, rate: 3575 },
  { trip: "Abu Dhabi to Dubai", truckType: "12M Flatbed", ton: 22, rate: 900 },
  { trip: "Abu Dhabi to Industrial City of Abu Dhabi (ICAD)", truckType: "7 Ton Pickup", ton: 7, rate: 380 },
  { trip: "Abu Dhabi to Jebel Ali (JAFZA)", truckType: "7 Ton Pickup", ton: 14, rate: 700 },
  { trip: "Abu Dhabi to Kuwait", truckType: "12M Flatbed", ton: 20, rate: 4400 },
  { trip: "Abu Dhabi to Makkah", truckType: "13M Curtain Side", ton: 20, rate: 5200 },
  { trip: "Ajman to Jebel Ali (JAFZA)", truckType: "3 Ton Pickup", ton: 2, rate: 300 },
  { trip: "Dubai to Abu Dhabi (AUH)", truckType: "12M Flatbed", ton: 14, rate: 725 },
  { trip: "Dubai to Abu Dhabi (AUH)", truckType: "15M Flatbed", ton: 22, rate: 900 },
  { trip: "Dubai to Al Ain", truckType: "12M Flatbed", ton: 23, rate: 1139 },
  { trip: "Dubai to Al Ain", truckType: "15M Flatbed", ton: 24, rate: 1400 },
  { trip: "Dubai to Al Ain", truckType: "40Ton Lowbed", ton: 40, rate: 2400 },
  { trip: "Dubai to Al Qassim", truckType: "12M Flatbed", ton: 20, rate: 600 },
  { trip: "Dubai to Al Quoz", truckType: "12M Flatbed", ton: 22, rate: 400 },
  { trip: "Dubai to Al Quoz", truckType: "7 Ton Pickup", ton: 5, rate: 330 },
  { trip: "Dubai to Bahrain", truckType: "12M Flatbed", ton: 20, rate: 3450 },
  { trip: "Dubai to Bahrain", truckType: "Reefer", ton: 15, rate: 4200 },
  { trip: "Dubai to Cargo Village", truckType: "10 Ton Pickup", ton: 10, rate: 250 },
  { trip: "Dubai to Dafza", truckType: "12M Flatbed", ton: 20, rate: 650 },
  { trip: "Dubai to Dammam", truckType: "12M Flatbed", ton: 18, rate: 3000 },
  { trip: "Dubai to Dammam", truckType: "3 Ton Pickup", ton: 3, rate: 1400 },
  { trip: "Dubai to Dammam", truckType: "40Ton Lowbed", ton: 40, rate: 8250 },
  { trip: "Dubai to Dammam", truckType: "60Ton Lowbed", ton: 60, rate: 12375 },
  { trip: "Dubai to DUBA", truckType: "12M Flatbed", ton: 21, rate: 6250 },
  { trip: "Dubai to Dubai", truckType: "3 Ton Pickup", ton: 3, rate: 210 },
  { trip: "Dubai to Dubai", truckType: "7 Ton Pickup", ton: 5, rate: 400 },
  { trip: "Dubai to Dubai Investments Park (DIP)", truckType: "12M Flatbed", ton: 22, rate: 400 },
  { trip: "Dubai to Industrial City of Abu Dhabi (ICAD)", truckType: "7 Ton Pickup", ton: 7, rate: 650 },
  { trip: "Dubai to Jebel Ali (JAFZA)", truckType: "10 Ton Pickup", ton: 7, rate: 370 },
  { trip: "Dubai to Jebel Ali (JAFZA)", truckType: "12M Flatbed", ton: 20, rate: 294 },
  { trip: "Dubai to Jebel Ali (JAFZA)", truckType: "13M Curtain Side", ton: 21, rate: 372 },
  { trip: "Dubai to Jebel Ali (JAFZA)", truckType: "3 Ton Pickup", ton: 3, rate: 150 },
  { trip: "Dubai to Jebel Ali (JAFZA)", truckType: "7 Ton Pickup", ton: 7, rate: 150 },
  { trip: "Dubai to Makkah", truckType: "13M Closed Box", ton: 8, rate: 5200 },
  { trip: "Dubai to Muscat", truckType: "12M Flatbed", ton: 20, rate: 4900 },
  { trip: "Dubai to Mussafah", truckType: "12M Flatbed", ton: 21, rate: 1101 },
  { trip: "Dubai to Mussafah", truckType: "15M Flatbed", ton: 18, rate: 950 },
  { trip: "Dubai to National Industries Park (Technopark)", truckType: "12M Flatbed", ton: 20, rate: 425 },
  { trip: "Dubai to National Industries Park (Technopark)", truckType: "7 Ton Pickup", ton: 7, rate: 225 },
  { trip: "Dubai to Neom", truckType: "12M Flatbed", ton: 15, rate: 5800 },
  { trip: "Dubai to Ras Al Khor", truckType: "12M Flatbed", ton: 20, rate: 600 },
  { trip: "Dubai to Riyadh", truckType: "15M Flatbed", ton: 22, rate: 3400 },
  { trip: "Dubai to Riyadh", truckType: "3 Ton Pickup", ton: 3, rate: 1600 },
  { trip: "Dubai to Riyadh", truckType: "Reefer", ton: 16, rate: 150 },
  { trip: "Dubai to Salalah", truckType: "12M Flatbed", ton: 22, rate: 4500 },
  { trip: "Dubai to Sharjah", truckType: "10 Ton Pickup", ton: 10, rate: 700 },
  { trip: "Dubai to Sharjah", truckType: "12M Flatbed", ton: 20, rate: 600 },
  { trip: "Dubai to Umluj", truckType: "15M Flatbed", ton: 21, rate: 200 },
  { trip: "Dubai to Umm Al Quwain", truckType: "12M Flatbed", ton: 22, rate: 800 },
  { trip: "KSA to Ras Al Khor", truckType: "12M Flatbed", ton: 15, rate: 1550 },
  { trip: "KSA to Ras Al Khor", truckType: "15M Flatbed", ton: 20, rate: 1800 },
  { trip: "Ras Al-Khaimah to Jeddah", truckType: "13M Curtain Side", ton: 18, rate: 5500 },
  { trip: "Ras Al-Khaimah to Neom", truckType: "12M Flatbed", ton: 20, rate: 6800 },
  { trip: "Sharjah to Jebel Ali (JAFZA)", truckType: "13M Curtain Side", ton: 20, rate: 900 },
  { trip: "Sharjah to Jebel Ali (JAFZA)", truckType: "15M Curtain Side", ton: 22, rate: 750 },
  { trip: "Sharjah to King Fahd International Airport (KFIA)", truckType: "10 Ton Pickup", ton: 7, rate: 2400 },
  { trip: "Sharjah to Riyadh", truckType: "10 Ton Pickup", ton: 10, rate: 3900 },
  { trip: "Sharjah to Riyadh", truckType: "15M Flatbed", ton: 16, rate: 3400 },
  { trip: "Sharjah to Salalah", truckType: "12M Flatbed", ton: 10, rate: 3500 },
  { trip: "Umm Al Quwain to Kuwait", truckType: "12M Closed Box", ton: 20, rate: 5450 },






];
