
import reefer from '~/components/assets/reefer.png';
import pickupTruck from '~/components/assets/pickupTruck.png';
import flatbed from '~/components/assets/flatbed.png';
import lowbed from '~/components/assets/lowbed.png';
import closed from '~/components/assets/closed.png';
import curtain from '~/components/assets/curtain.png';
import { MetaFunction } from '@remix-run/node';
import { Button } from "~/components/ui/button";
import { tripData } from '~/components/assets/svg/TripData';
import FaqContainer from '~/components/assets/svg/FaqContainer';
import { TestimonialSection } from '~/components/assets/svg/Testimonial';
import util from '~/utils/util';



export const meta: MetaFunction = () => {
  return [
    ...util.baseMeta,
    { title: "Land Transportation Services & Logistics Company in UAE" },
    {
      property: "og:title",
      content: "Land Transport, Logistics Company in UAE; Road Transportation Service in UAE",
    },
    {
      name: "description",
      content: "Need land transportation services in the UAE? FR8, as one of the top logistics companies in UAE, provides truck rental services to deliver your goods on time.",
    },
  ];
};

const Home = () => {
  const tableHeader = ["Trip", "Truck Type", "Ton", "Rate (AED)", ""];

  const onClickButton = () => {
    const whatsappURL =
      "https://api.whatsapp.com/send?phone=+97142381385&text=Hi%20FR8,%20I%20want%20to%20know%20more%20about%20your%20transport%20service%20in%20UAE.&app_absent=0";
    window.open(whatsappURL, "_blank");
  };

  return (
    <>

      <section className="relative pb-6">
        <div className="relative z-10 mx-auto px-4 pt-8">
          <div className="text-center">
            <h1 className="text-l md:text-5xl font-bold heading  mb-4">
              Truck Transport & Logistics<br />
              Company in <span className="text-secondary">UAE</span>
            </h1>
            <div className="text-sm md:text-xl mt-6">
              <p className="tracking-wider" style={{ fontSize: "16px" }}>
                Rent trucks from a vast network of flatbed trucks, pickup trucks, reefer trucks,
                <span className="hidden md:inline"> <br /></span>
                and more from OpenFR8 - one of the best logistics companies in the UAE.
              </p>
            </div>
          </div>

          {/* Container without width constraints */}
          <div className="custom-container sm:px-4 w-full max-w-7xl mx-auto">
            <div className="overflow-auto">
              <div className="pt-4 inline-block w-full md:px-3 lg:px-8">
                <div className="shadow-sm w-full">
                  <table className="min-w-full table-auto w-full">
                    <thead className="bg-primary sticky top-0 z-10">
                      <tr>
                        {tableHeader.map((title, i) => (
                          <th
                            scope="col"
                            key={i}
                            className={`text-sm font-semibold text-white px-4 py-2 drop-shadow-solid text-left ${title === "Action" ? "sticky right-0 bg-primary" : ""
                              }`}
                          >
                            {title}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tripData.map((tp, i) => (
                        <tr key={i} className={`${i % 2 === 0 ? "bg-white" : "bg-blue-50"}`}>
                          <td className="px-3 py-1 text-sm text-gray-900 text-left truncate">{tp.trip}</td>
                          <td className="px-3 py-1 text-sm text-gray-900 text-left truncate">{tp.truckType}</td>
                          <td className="px-3 py-1 text-sm text-gray-900 text-center truncate">{tp.ton || "-"}</td>
                          <td className="px-3 py-1 text-sm text-gray-900 text-center truncate">{tp.rate}</td>
                          <td
                            className={`px-3 py-1 text-sm text-gray-900 text-left truncate sticky right-0 ${i % 2 === 0 ? "bg-white" : "bg-blue-50"
                              }`}
                          >
                            <Button
                              onClick={onClickButton}
                              variant={"secondary"}
                              className="text-xs py-1 px-3 h-7"
                            >
                              Book Truck
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      {/* Section 2 */}
      <section className="mt-12 bg-slate-50">
        <br></br>
        <br></br>

        <h2 className="text-xl md:text-4xl text-center font-bold heading mb-4">Types of Truck We Provide</h2>

        <div className='max-w-6xl mx-auto pb-4 md:py-10'>
          <div className="grid grid-cols-1 lg:grid-cols-2 ">

            <div className="order-2 lg:order-1 text-sm px-6 md:px-4">
              <h3 className="text-xl tracking-wider md:text-3xl font-semibold heading  mb-4">Pickup Truck Rental</h3>
              <p className="mb-6 tracking-wider leading-relaxed">Need a pickup truck rental? From 1-ton pickup trucks for small deliveries to 10-ton trucks for large shipments, we offer the best pickup truck options to suit your needs. Our trucks are perfect for quick, efficient deliveries across the UAE, with easy loading and unloading. Book your pickup truck now!</p>

            </div>
            <div className="order-1 lg:order-2 flex justify-center items-center">
              <img
                src={pickupTruck}
                alt="Pickup Truck"
                width={400}
                height={400}
              />

            </div>
          </div>
        </div>

        <div className='bg-tertiary py-6 md:py-10'>
          <div className="max-w-6xl mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2">

              <div className="order-1 lg:order-1 flex justify-center items-center">
                <img
                  src={curtain}
                  alt="Curtain Side Truck"
                  width={400}
                  height={400}
                />
              </div>

              <div className="order-2 lg:order-2 text-sm px-6 md:px-4">
                <h3 className="text-xl md:text-3xl font-semibold heading mb-4">Curtain Side Truck Rental</h3>
                <p className="mb-6 tracking-wider leading-relaxed">When you need easy access to your cargo, our curtain side truck rental is perfect. Offering the versatility of both open and enclosed transport, these trucks provide fast loading and unloading and are ideal for goods that need quick access during transit. Book your curtain side truck now.</p>
              </div>
            </div>
          </div>
        </div>

        <div className='max-w-6xl mx-auto py-4 md:py-10'>
          <div className="grid grid-cols-1 lg:grid-cols-2 ">
            <div className="order-2 lg:order-1 text-sm  px-6 md:px-4">
              <h3 className="text-xl md:text-3xl font-semibold heading  mb-4">Reefer Truck Rental</h3>
              <p className="mb-6 tracking-wider leading-relaxed">Transport temperature-sensitive goods with ease using our reefer truck rental. Our fleet includes reefer containers and trucks that ensure your goods stay at the perfect temperature throughout the journey. Need a refrigerated truck? We’ve got you covered with reliable options. Book a reefer truck today.</p>
            </div>

            <div className="order-1 lg:order-2 flex justify-center items-center">
              <img
                src={reefer}
                alt="Reefer Truck"
                width={400}
                height={400}
              />
            </div>
          </div>
        </div>
        <div className='bg-tertiary py-6 md:py-10'>
          <div className="max-w-6xl mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2">

              <div className="order-1 lg:order-1 flex justify-center items-center">
                <img
                  src={closed}
                  alt="Closed Box Truck"
                  width={400}
                  height={400}
                />
              </div>

              <div className="order-2 lg:order-2 text-sm px-6 md:px-4">
                <h3 className="text-xl md:text-3xl font-semibold heading mb-4">Closed Box Truck Rental</h3>
                <p className="mb-6 tracking-wider leading-relaxed">Keep your goods safe with our closed box truck rental options. Our secure trucks ensure your cargo is protected during transport, offering a shield from weather and theft. With the added benefit of confidentiality and security, these trucks are perfect for transporting sensitive or high-value goods. Rent a closed box truck now.</p>
              </div>
            </div>
          </div>
        </div>

        <div className='max-w-6xl mx-auto py-4 md:py-10'>
          <div className="grid grid-cols-1 lg:grid-cols-2 ">
            <div className="order-2 lg:order-1 text-sm  px-6 md:px-4">
              <h3 className="text-xl md:text-3xl font-semibold heading  mb-4">Flatbed Truck Rental</h3>
              <p className="mb-6 tracking-wider leading-relaxed">For flexible transport, our flatbed truck rental service is the solution. Whether you need a flatbed trailer or a simple flatbed, we offer reliable trucks for moving large equipment, construction materials, or bulky cargo. Get your flatbed truck today and simplify your transport.</p>
            </div>

            <div className="order-1 lg:order-2 flex justify-center items-center">
              <img
                src={flatbed}
                alt="Flatbed Truck"
                width={450}
                height={500}
              />
            </div>
          </div>
        </div>

        <div className='bg-tertiary py-6 md:py-10'>
          <div className="max-w-6xl mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2">

              <div className="order-1 lg:order-1 flex justify-center items-center">
                <img
                  src={lowbed}
                  alt="Low Bed Truck"
                  width={400}
                  height={400}
                />
              </div>

              <div className="order-2 lg:order-2 text-sm px-6 md:px-4">
                <h3 className="text-xl md:text-3xl font-semibold heading mb-4">Low Bed Truck Rental</h3>
                <p className="mb-6 tracking-wider leading-relaxed">Need to move heavy or oversized cargo? Our low bed truck rental offers the perfect solution. With specialized design for large equipment and machinery, our low bed trucks ensure safe transport for your most challenging loads. Get your low bed truck now and move with confidence.</p>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* Section 3 */}
      <section>
        <TestimonialSection /> <br></br>
      </section>

      {/* Section 4 */}
      <section className='bg-slate-50'><br></br>
        <FaqContainer />
      </section>
      <br></br>
    </>
  );
};

export default Home;