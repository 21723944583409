export const faqData = {
  "": [
    {
      key: 1,
      questionName: "What types of trucks can I rent with OpenFR8?",
      acceptedAnswerText:
        '<p>We offer different types of trucks, including flatbed trucks, pickup trucks, reefer trucks, trailer trucks, container trucks, and low bed trucks, catering to diverse transport truck needs across the UAE.</p>',
    },
    {
      key: 2,
      questionName: "Does OpenFR8 offer transportation for heavy or oversized cargo?",
      acceptedAnswerText:
        "<p>Yes, we specialize in handling heavy and oversized cargo with our low bed trucks and flatbed trailers, designed for safe and efficient transportation of large machinery and equipment.</p>",
    },
    {
      key: 3,
      questionName: "Where do you provide logistics services in the UAE?",
      acceptedAnswerText:
        "<p>We provide logistics and truck rental services across the UAE, covering major cities and industrial hubs, ensuring your goods are delivered efficiently and on time.</p>",
    },
    {
      key: 4,
      questionName: `I'm a small business owner. Can I use OpenFR8 for my truck rental needs?`,
      acceptedAnswerText:
        '<p>As a leading transport company, we provide current transport truck rental rates directly on our home page.</p>',
    },
    {
      key: 5,
      questionName: "Why should I choose OpenFR8 over other logistics companies in UAE?",
      acceptedAnswerText:
        "<p>OpenFR8 stands out for its vast network of verified trucks, reliable service, transparent pricing, and on-time delivery. We are committed to offering the best logistics services in UAE tailored to your needs.</p>",
    },
    {
      key: 6,
      questionName: "Where is the OpenFR8 logistics company located?",
      acceptedAnswerText:
        '<p>Our office is situated at the Dubai Industrial Park 1, Dubai.</p>',
    },
    {
      key: 7,
      questionName: "How can I check transport truck rates?",
      acceptedAnswerText:
        '<p>As a leading transport company, we provide current transport truck rental rates directly on our home page.</p>',
    },
  ],
};
