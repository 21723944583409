import React, { useState } from "react";

interface Testimonial {
  id: number;
  name: string;
  designation: string;
  message: string;
}

const testimonials: Testimonial[] = [
  {
    id: 1,
    name: "Ananth",
    designation: "CEO, Freelance Logistics",
    message:
      "We have a great experience with OpenFR8. They deliver our goods on time, and their truck rates are very competitive. The customer support team is also very supportive. Definitely a 5-star service! Join OpenFR8.",
  },
  {
    id: 2,
    name: "John Doe",
    designation: "CEO, TechCorp",
    message:
      "OpenFR8 is one of the best logistics companies in the UAE! Their seamless truck booking system and wide range of transport trucks, from pickup trucks to reefer trucks, made my job so much easier. Their team is professional, always on time, and ensures every shipment is handled with care. Highly recommended!",
  },
  {
    id: 3,
    name: "Jane Smith",
    designation: "Manager, Global Exports",
    message:
      "If you're looking for reliable transport companies in Dubai, OpenFR8 is the way to go. Their low bed trucks and flatbed trailers are perfect for my transport needs in the UAE. From heavy machinery to oversized cargo, they handle everything efficiently with top-notch customer service.",
  },
  {
    id: 4,
    name: "Arjun Patel",
    designation: "Director, Patel Industries",
    message:
      "As a business owner, I rely on OpenFR8 for all my heavy-duty truck rental needs. Their service is unmatched, making them one of the top logistics companies in the UAE. Their transparent pricing and ability to accommodate urgent requests have saved me time and stress multiple times.",
  },
  {
    id: 5,
    name: "Ayesha Khan",
    designation: "Owner, Khan Textiles",
    message:
      "I needed a trusted logistics company near me, and OpenFR8 delivered! Their transport and logistics services are fast, efficient, and reliable. They provide real-time tracking and always communicate updates, making logistics hassle-free for my business.",
  },
  {
    id: 6,
    name: "Carlos Lopez",
    designation: "Operations Head, CargoPro",
    message:
      "OpenFR8 is my go-to for truck rental and logistics services in Abu Dhabi. Their network of verified trucks and professional service is the best among the list of logistics companies in UAE. They always prioritize safety and on-time delivery, making them a partner I can trust for all my transportation needs.",
  },
];

export const TestimonialSection: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleDotClick = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <section className="py-10">
      <div className="custom-container mx-auto text-center">
        <br></br>
        <h2 className="text-xl md:text-3xl font-semibold heading mb-4">
          Testimonial <br /> Why Customers Choose OpenFR8
        </h2>
        <div className="relative max-w-3xl mx-auto"><br></br>
          <p>
            "{testimonials[currentIndex].message}"
          </p>
          <div className="mt-4"><br></br>
            <h4 className="text-primary-900 font-medium text-xl">
              {testimonials[currentIndex].name}
            </h4>
            <p className="text-sm">
              {testimonials[currentIndex].designation}
            </p>
          </div>

          {/* Dots Navigation */}
          <div className="flex justify-center mt-6 space-x-2">
            {testimonials.map((testimonial, index) => (
              <button
                key={testimonial.id}
                onClick={() => handleDotClick(index)}
                className={`w-3 h-3 rounded-full ${
                  currentIndex === index
                    ? "bg-primary-900"
                    : "bg-gray-400"
                } hover:bg-primary-700`}
                aria-label={`Go to testimonial ${index + 1}`}
              ></button>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
