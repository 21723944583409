import React from "react";
import { ChevronDown, ChevronUp } from "lucide-react";  // You can use any icon library you prefer

// AccordionItem component to render individual FAQ items
interface AccordionItemProps {
  faq: {
    key: number;
    questionName: string;
    acceptedAnswerText: string;
  };
  active: number | null;
  handleToggle: (index: number) => void;
}

export const AccordionItem: React.FC<AccordionItemProps> = ({
  faq,
  active,
  handleToggle,
}) => {
  const { questionName, acceptedAnswerText, key } = faq;

  const onToggle = () => handleToggle(key); // Trigger toggle for the specific FAQ

  return (
    <div className="mb-2 rounded-lg bg-white shadow-md">
      <div
        className={`flex justify-between items-center cursor-pointer p-3 ${
          active === key ? "active" : ""
        } hover:shadow-md`}
        onClick={onToggle} // Handle click to toggle the question
      >
        <h4 className="text-slate-800 font-semibold text-xl">{questionName}</h4>
        {active === key ? (
          <ChevronUp className="w-4 h-10" />
        ) : (
          <ChevronDown className="w-4 h-10" />
        )}
      </div>

      <div
        className={`${
          active === key ? "block" : "hidden"
        } transition-height duration-500 ease-in-out border-t`}
      >
        <div className="rc-accordion-body p-3">
          <div className="text-lg font-normal text-slate-600">
            <div
              dangerouslySetInnerHTML={{ __html: acceptedAnswerText }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};
