import React, { useState } from "react";
import { faqData } from "./faqData"; // Import your FAQ data
import { AccordionItem } from "./AccordionItem"; // Import the AccordionItem component

const FaqContainer: React.FC = () => {
  const [active, setActive] = useState<number | null>(null); // To track the active FAQ

  const handleToggle = (index: number) => {
    setActive(active === index ? null : index); // Toggle the FAQ (open/close)
  };

  return (
    <section>
  <div className="custom-container px-4 lg:px-0 lg:w-2/3 mx-auto">
    <div className="text-center">
      <br></br>
      <h2 className="text-xl md:text-3xl font-semibold heading mb-4">FAQs</h2>
    </div>
    <div className="accordion space-y-4" id="faqAccordion">
      {/* Render AccordionItem for each FAQ */}
      {faqData[""].map((faq) => (
        <AccordionItem
          key={faq.key}
          active={active} // Pass active state to determine which FAQ is open
          handleToggle={handleToggle} // Pass the toggle function
          faq={faq} // Pass the FAQ data
        />
      ))}
    </div>
  </div>
</section>

  );
};

export default FaqContainer;
